/* TODO: refactor from deprecated @sanity/block-content-to-react to @portabletext/react */
import BlockContent, { BlockContentProps } from '@sanity/block-content-to-react';
import React from 'react';

interface SanityBlockComponentProps extends React.HTMLAttributes<HTMLElement> {
  input: any;
  variables?: any;
}

interface BlockRendererProps {
  children: React.ReactNode;
  node: {
    style: string;
  };
}
const BlockRenderer = (props: BlockRendererProps) => {
  const style = props.node.style || 'normal';

  // TODO: Fix heading logic
  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '');
    const Heading: any = `h${level}`;
    return <Heading>{props.children}</Heading>;
  }

  return style === 'blockquote' ? (
    <blockquote>{props.children}</blockquote>
  ) : (
    <p style={{ width: '100%' }}>{props.children}</p>
  );
};

export const replaceVariablesInText = (inputText, variables) => {
  let text = inputText;

  for (const key in variables) {
    if (variables.hasOwnProperty(key)) {
      let value = variables[key];

      if (value && value.indexOf('$') >= 0) {
        value = value
          .split('$')
          .map((v, index) => (index === 0 ? v : `$$${v}`))
          .join('');
      }
      const pattern = '\\${' + key + '}';
      const regex = new RegExp(pattern, 'g');

      text = text.replace(regex, value);
    }
  }
  return text;
};

export const replaceWithVariables = (input, variables) => {
  if (!variables) {
    return input;
  }
  const convert = (it) => ({
    ...it,
    children: it.children.map((child) => {
      const text = replaceVariablesInText(child.text, variables);
      return { ...child, text };
    }),
  });
  if (Array.isArray(input)) {
    return input.map(convert);
  }
  return convert(input);
};

const serializers: BlockContentProps['serializers'] = {
  // FIXME: when migrating to @portabletext/react
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  list: ({ type, children }) => {
    if (type === 'bullet') {
      return <ul className="list-disc">{children}</ul>;
    }
    return <ol className="list-decimal">{children}</ol>;
  },
  // // FIXME: when migrating to @portabletext/react
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  listItem: ({ children }) => <li className="ml-40 first-of-type:mt-16 last-of-type:mb-16 ">{children}</li>,
  marks: {
    link: ({ mark, children }) => {
      return (
        <a target="_blank" href={mark.href} className="underline">
          {children}
        </a>
      );
    },
  },
};

const SanityBlockComponent: React.FC<SanityBlockComponentProps> = ({ input, variables, className }) => {
  const parsedInput = replaceWithVariables(input, variables);

  return (
    <BlockContent
      className={className}
      blocks={parsedInput}
      renderContainerOnSingleChild={Boolean(className)}
      serializers={{ ...serializers, types: { block: BlockRenderer } }}
    />
  );
};

export default SanityBlockComponent;
